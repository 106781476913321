import axios from "axios";

//'https://antesala.com.ar/backend/public'
//'http://127.0.0.1:8000/

let clientAxios = axios.create({
  //baseURL: 'http://127.0.0.1:8000/'
  baseURL: 'https://antesala.com.ar/backend/public'
});

clientAxios.defaults.headers.common["Content-Type"] = "application/json";

clientAxios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  const adress = localStorage.getItem("adress");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  config.headers.adress = adress ? `${adress}` : "";

  return config;
});

export default clientAxios;
