import { format } from "date-fns";

const Footer = () => {
  return (
    <footer class="bg-black pt-4">
      <div class="container">
        <div class="row">
          <div class="col-md-1 d-flex justify-content-center justify-content-md-center me-2">
            <img
              src="/assets/img/marca/antesala-logo-white.svg"
              width="100px"
              alt=""
            />
          </div>
          <div class="col-md-2">
            <ul class="mb-0 p-0">
              <li class="pb-2">
                <a href="#" class="GothamLight fc-blanco">
                  Quienes Somos
                </a>
              </li>
              <li class="pb-2">
                <a href="#" class="GothamLight fc-blanco">
                  Pauta con nosotros
                </a>
              </li>
              <li class="pb-2">
                <a href="/terminos-y-condiciones" class="GothamLight fc-blanco">
                  Términos y Condiciones
                </a>
              </li>
              <li>
                <a href="#" class="GothamLight fc-blanco">
                  Politicas de Privacidad
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-2">
            <h1
              class="GothamLight fc-blanco mt-4 mt-md-0 fw-bold"
              style={{ fontSize: "13px" }}
            >
              SALAS Y PRODUCTORXS
            </h1>
            <ul class="p-0">
              <li class="pb-2">
                <a href="#" class="GothamLight fc-blanco">
                  Iniciar Sesión
                </a>
              </li>
              <li class="pb-2">
                <a href="#" class="GothamLight fc-blanco">
                  Registrarse
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-2">
            <h1
              class="GothamLight fc-blanco mt-4 mt-md-0 fw-bold"
              style={{ fontSize: "13px" }}
            >
              USUARIOS
            </h1>
            <ul class="mb-0 p-0">
              <li class="pb-2">
                <a href="#" class="GothamLight fc-blanco">
                  Iniciar Sesión
                </a>
              </li>
              <li>
                <a href="#" class="GothamLight fc-blanco">
                  Registrate
                </a>
              </li>
              <li class="pb-2">
                <a href="#" class="GothamLight fc-blanco">
                  Recuperar Cuenta
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-2">
            <ul class="mb-0 p-0 mt-4 mt-md-0">
              <li class="pb-2">
                <a href="#" class="fs-3 fc-blanco me-2">
                  <i class="bi bi-facebook"></i>
                </a>
                <a href="#" class="fs-3 fc-blanco me-2">
                  <i class="bi bi-instagram"></i>
                </a>
              </li>
              <li>
                <a href="#" class="GothamLight fc-blanco">
                  antesala.cordoba@gmail.com
                </a>
              </li>
              <li className="d-flex">
                <img
                  src="/assets/img/tarjeta-naranja.svg"
                  className="img-fluid"
                  style={{ width: "50px" }}
                  alt=""
                />
                <img
                  src="/assets/img/mastercard.svg"
                  className="img-fluid"
                  style={{ width: "50px" }}
                  alt=""
                />
                <img
                  src="/assets/img/maestro.svg"
                  className="img-fluid"
                  style={{ width: "50px" }}
                  alt=""
                />
                <img
                  src="/assets/img/cabal.svg"
                  className="img-fluid"
                  style={{ width: "50px" }}
                  alt=""
                />
                <img
                  src="/assets/img/amex.svg"
                  className="img-fluid"
                  style={{ width: "50px" }}
                  alt=""
                />
                <img
                  src="/assets/img/visa.svg"
                  className="img-fluid"
                  style={{ width: "50px" }}
                  alt=""
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container-fluid bg-white mt-100 p-4">
        <p class="Grifter m-0">{`© Copyright - 2022 - ${format(new Date(), 'Y').toString()} | Antesala.`}</p>
      </div>
    </footer>
  );
};

export default Footer;
