import { useEffect, useState } from "react";
import Card from "../components/pago/Card.jsx";
import { PATH_EVENTO_PRODUCTION } from "../../const/PATH.js";
import { format } from "date-fns";

const Pago = ({ evento, info, setInfo, onChange, onClick, required }) => {
  return (
    <div className="container-md min-vh-100 py-5">
      <div className="d-flex justify-content-center align-items-center px-1 px-md-0">
        <div className="row w-100">
          <div className="col-md-8 col-12 ">
            <h3 className="GothamBold">Ingresá los datos de tu tarjeta.</h3>
            <div class="mb-3">
              {/*<Card info={info} />*/}
              <label for="exampleFormControlInput1" class="form-label fw-bold">
                Número de tarjeta
              </label>
              <input
                type="text"
                class="form-control py-3 border-dark"
                style={{ borderRadius: "20px" }}
                placeholder=""
                value={info.numero}
                name="numero"
                onChange={(e) => onChange(e)}
                required
              />
              <span className={`text-danger fw-bold ${!required.numero ? 'd-none':''}`}>El numero de la tarjeta es obligatorio</span>
            </div>
            <div className="mb-3">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  value={"debito"}
                  name="tipo"
                  onChange={(e) => onChange(e)}
                />
                <label class="form-check-label fw-bold" for="flexRadioDefault1">
                  Debito
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  value={"credito"}
                  name="tipo"
                  onChange={(e) => onChange(e)}
                />
                <label class="form-check-label fw-bold" for="flexRadioDefault2">
                  Credito
                </label>
              </div>
              <span className={`text-danger fw-bold ${!required.credito && !required.debito ? '':'d-none'}`}>Selecciona que tarjeta estas utilizando</span>
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label fw-bold">
                Nombre y Apellido
              </label>
              <input
                type="text"
                class="form-control py-3 border-dark"
                name="titular"
                onChange={(e) => onChange(e)}
                style={{ borderRadius: "20px" }}
              />
              <span className={`text-danger fw-bold ${!required.titular ? 'd-none':''}`}>El nombre y apellido del titular es obnligatorio</span>
            </div>
            <div className="d-flex">
              <div class="mb-3 me-2">
                <label
                  for="exampleFormControlInput1"
                  class="form-label fw-bold"
                >
                  Fecha de vencimiento
                </label>
                <input
                  type="text"
                  class="form-control py-3 border-dark"
                  placeholder="MM/AAAA"
                  value={info.vencimiento}
                  maxlength="7"
                  name="vencimiento"
                  onChange={(e) => onChange(e)}
                  style={{ borderRadius: "20px" }}
                />
              <span className={`text-danger fw-bold ${!required.vencimiento ? 'd-none':''}`}>La fecha de vencimiento es obligatorio</span>
              </div>

              <div class="mb-3">
                <label
                  for="exampleFormControlInput1"
                  class="form-label fw-bold"
                >
                  Código de seguridad
                </label>
                <input
                  class="form-control py-3 border-dark"
                  style={{ borderRadius: "20px" }}
                  type="password"
                  maxlength="7"
                  name="codigo"
                  onChange={(e) => onChange(e)}
                  onFocus={(e) => setInfo({ ...info, focusCVV: true })}
                  onBlur={(e) => setInfo({ ...info, focusCVV: false })}
                />
              <span className={`text-danger fw-bold ${!required.codigo ? 'd-none':''}`}>El codigo de seguridad es obligatorio</span>
              </div>
            </div>
            <h3>Datos personales</h3>
            Lo vamos a utilizar para enviarte tu entrada o contactarte en caso
            de que sea necesario.
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label fw-bold">
                E-Mail
              </label>
              <input
                type="text"
                class="form-control py-3 border-dark"
                style={{ borderRadius: "20px" }}
              />
              <span className={`text-danger fw-bold ${!required.email ? 'd-none':''}`}>El mail es necesario para recibir tus entradas!</span>
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label fw-bold">
                Teléfono
              </label>
              <input
                type="text"
                class="form-control py-3 border-dark"
                style={{ borderRadius: "20px" }}
              />
              <span className={`text-danger fw-bold ${!required.telefono ? 'd-none':''}`}>El telefono es necesario para poder contactarte!</span>
            </div>
          </div>
          <div className="col-md-4 bg-light">
            <div
              className="border"
              style={{
                backgroundImage: `url(https://antesala.com.ar/assets/img/eventos/${evento.evento.imagen})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "30px",
                height: "300px",
              }}
            ></div>
            <ul class="list-group bg-transparent">
              <li class="list-group-item border-0 bg-transparent">
                <span className="fw-bold">{evento.evento.nombre}</span>
              </li>
              <li class="list-group-item border-0 bg-transparent mb-3">
                <span className="fw-bold">{evento.select.fecha}</span>
                {evento.evento.entradas.map((entrada, index) => {
                  if (entrada.quantity > 0) {
                    return (
                      <div className="mb-2" key={index}>
                        <div className="d-flex justify-content-between">
                          <span>Precio</span>
                          <span>{`ARS ${entrada.precio}`}</span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <span>Cantidad</span>
                          <span>{`X ${entrada.quantity}`}</span>
                        </div>
                      </div>
                    );
                  }
                })}
              </li>
              <li class="list-group-item border-0 bg-transparent">
                <div className="d-flex justify-content-between mb-3">
                  <span>Sub Total</span>
                  <span>{`ARS ${evento.info.subTotal}`}</span>
                </div>
                <div className="d-flex justify-content-between mb-3">
                  <span>{`+ Serv ${evento.evento.comisiones.tasa}%`}</span>
                  <span>{`ARS ${evento.info.serv}`}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="fw-bold">Total</span>
                  <span>{`ARS ${evento.info.total}`}</span>
                </div>
              </li>

              <li class="list-group-item border-0 bg-transparent d-none">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" />
                  <label class="form-check-label">Utilizar gift card</label>
                </div>

                <input
                  type="text"
                  class="satin-bright-input"
                  placeholder="CODIGO"
                />
              </li>
              <li class="list-group-item border-0 bg-transparent">
                <label className="btn btn-custom-naranja text-white py-3 w-100 mb-1">
                  Pagar
                  <button
                    className="d-none"
                    name="pagar"
                    onClick={(e) => onClick(e)}
                  ></button>
                </label>
                <img
                  src="/assets/img/decidir.png"
                  className="img-fluid"
                  style={{ width: "100px" }}
                  alt=""
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container-fluid bg-white mt-100 p-4">
        <p class="Grifter m-0" style={{ fontSize: "10px" }}>
          {`© Copyright - 2022 - ${format(
            new Date(),
            "Y"
          ).toString()} | Antesala.`}{" "}
        </p>
      </div>
    </div>
  );
};

export default Pago;
