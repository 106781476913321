import { useEffect, useState } from "react";
import Pago from "../page/Pagar";
import apiAntesala from "../../config/ConfigPublic";

const regex = /^[0-9/]*$/;

const PagoController = () => {
  const [required, setRequired] = useState({
    debito: false,
    credito: false,
    numero: false,
    titular: false,
    vencimiento: false,
    codigo: false,
    tipo: false,
    focusCVV: false,
    email: false,
    telefono: false
  })
  const [info, setInfo] = useState({
    debito: false,
    credito: false,
    numero: "",
    titular: "",
    vencimiento: "",
    codigo: "",
    tipo: null,
    focusCVV: false,
    email: "",
    telefono: ""
  });
  const [evento, setEvento] = useState(null)
  const [metodoPago, setMetodoPago] = useState(null)

  const onChange = (e, value) => {
    switch (e.target.name) {
      case "tipo": {
        if(e.target.value == 'debito'){
          setInfo({...info, debito: true, credito: false});
        }
        
        if(e.target.value == 'credito'){
          setInfo({...info, debito: false, credito: true});
        }
      } break;
      case "vencimiento":
        {
          
          let value = e.target.value.replace(/\D/g, ""); // Eliminar caracteres no numéricos

          if (value.length >= 2) {
            value = value.slice(0, 2) + "/" + value.slice(2); // Añadir la barra
          }

          if (regex.test(value)) {
            return setInfo({
              ...info,
              [e.target.name]: value,
            });
          }
        }
        break;
      case "titular":
      case "codigo":
        {
          setInfo({
            ...info,
            [e.target.name]: e.target.value,
          });
        }
        break;
      case "numero":
        {
          if (isNaN(e.target.value)) {
            return null;
          }

          let tipo = null;
          if(e.target.value[0] == '4'){
            tipo = 'visa'
          }

          
          if(['51','52','53','54','55'].includes((e.target.value[0] + e.target.value[0]))){
            tipo = 'mastercard'
          }

          if(['50','56','69'].includes((e.target.value[0] + e.target.value[1]))){
            tipo = 'maestro'
          }

          if(['34','37'].includes((e.target.value[0] + e.target.value[1]))){
            tipo = 'amex'
          }
          

          setInfo({
            ...info,
            [e.target.name]: e.target.value.replace(
              new RegExp(" ".replace(/\./g, "\\."), "gi"),
              ""
            ),
            tipo: tipo
          });
        }
        break;
      default:
        break;
    }
  };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "pagar": {
        console.log(info);
        let _required = required;
        let error = false;
        Object.keys(required).map((key) => {
            if(key == 'debito' || key == 'credito'){
              _required[key] = info[key];
            }

            if(
              key !== 'debito' && 
              key !== 'credito' &&
               String(info[key]).trim() == ""){
              _required[key] = true;
              error = true;
            } else {
              _required[key] = false;
            }
        })
        setRequired({..._required});
      } break;
      default:
        break;
    }
  };

  const fetch = async (setData, params, url) => {
    const response = await apiAntesala.get(url, { params });
    if (response.status == 201 || response.status == 200) {
      setData(response.data);
    }
  };

  useEffect(() => {
    if(!evento){
      let local = localStorage.getItem('entradas');
      if(local){
        local = JSON.parse(local);
        setEvento(local);
      }
    }
  }, []);

  useEffect(() => {
    if(!metodoPago){
      fetch(setMetodoPago, {}, '/checkout/metodo-pago')
    }
  },[])
  if(!evento){
    return "";
  }

  console.log(required);
  
  return <Pago info={info} evento={evento} setInfo={setInfo} onChange={onChange} onClick={onClick} required={required}/>;
};

export default PagoController;
