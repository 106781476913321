import e from "cors";
import { format } from "date-fns";

const Filtro = ({
  provincias,
  setFilter,
  filter,
  fetch,
  setEventos,
  setSalas,
  salas,
  categorias,
}) => {
  const onChange = (e, value) => {
    switch (e.target.name) {
      case "fecha.desde":
        {
          let hasta = filter.hasOwnProperty("filter[fecha]")
            ? filter["filter[fecha]"].split(",")[1]
            : format(new Date(), "yyyy-M-dd").toString();

          let params = {
            ...filter,
            ["filter[fecha]"]: `${e.target.value},${hasta}`,
          };

          setFilter({ ...params });
          return fetch(setEventos, params, "/eventos");
        }
        break;
      case "fecha.hasta":
        {
          let desde = filter.hasOwnProperty("filter[fecha]")
            ? filter["filter[fecha]"].split(",")[0]
            : format(new Date(), "yyyy-M-dd").toString();

          let params = {
            ...filter,
            ["filter[fecha]"]: `${desde},${e.target.value}`,
          };

          setFilter({ ...params });
          return fetch(setEventos, params, "/eventos");
        }
        break;
      case "atp":
        {
          if (filter.hasOwnProperty("filter[atp]")) {
            let params = filter;
            delete params["filter[atp]"];
            setFilter({ ...params });
            setEventos({ data: null });
            return fetch(setEventos, params, "/eventos");
          }

          let params = {
            ...filter,
            ["filter[atp]"]: "SI",
          };

          setFilter({ ...params });
          return fetch(setEventos, params, "/eventos");
        }
        break;
      case "categoria":
        {
          if (!filter.hasOwnProperty("filter[category]")) {
            let params = {
              ...filter,
              ["filter[category]"]: value,
            };
            setFilter({ ...params });
            setEventos({ data: null });
            return fetch(setEventos, params, "/eventos");
          }

          if (
            filter["filter[category]"].split(",").length == 1 &&
            filter["filter[category]"].indexOf(value) !== -1
          ) {
            let params = filter;
            delete params["filter[category]"];
            setFilter({ ...params });
            setEventos({ data: null });
            return fetch(setEventos, params, "/eventos");
          }

          if (filter["filter[category]"].indexOf(value) !== -1) {
            let params = {
              ...filter,
              ["filter[category]"]: filter["filter[category]"].replace(
                "," + value,
                ""
              ),
            };

            setFilter({ ...params });
            setEventos({ data: null });
            return fetch(setEventos, params, "/eventos");
          } else {
            let params = {
              ...filter,
              ["filter[category]"]: `${filter["filter[category]"]},${value}`,
            };

            setFilter({ ...params });
            setEventos({ data: null });
            return fetch(setEventos, params, "/eventos");
          }
        }
        break;
      case "grupo":
        {
          if (!filter.hasOwnProperty("filter[grupo]")) {
            let params = {
              ...filter,
              ["filter[grupo]"]: value,
            };
            setFilter({ ...params });
            setEventos({ data: null });
            return fetch(setEventos, params, "/eventos");
          }

          if (
            filter["filter[grupo]"].split(",").length == 1 &&
            filter["filter[grupo]"].indexOf(value) !== -1
          ) {
            let params = filter;
            delete params["filter[grupo]"];
            setFilter({ ...params });
            setEventos({ data: null });
            return fetch(setEventos, params, "/eventos");
          }

          if (filter["filter[grupo]"].indexOf(value) !== -1) {
            let params = {
              ...filter,
              ["filter[grupo]"]: filter["filter[grupo]"].replace(
                "," + value,
                ""
              ),
            };

            setFilter({ ...params });
            setEventos({ data: null });
            return fetch(setEventos, params, "/eventos");
          } else {
            let params = {
              ...filter,
              ["filter[grupo]"]: `${filter["filter[grupo]"]},${value}`,
            };

            setFilter({ ...params });
            setEventos({ data: null });
            return fetch(setEventos, params, "/eventos");
          }
        }
        break;
      case "search":
        {
          if (e.target.value.length == 0) {
            setSalas({ data: null });
          }

          if (e.target.value.length > 3) {
            let params = {
              ["filter[nombre]"]: e.target.value,
            };

            return fetch(setSalas, params, "/salas");
          }
        }
        break;
      default:
        break;
    }
  };

  const onClick = (e, value) => {
    switch (e.target.name) {
      case "provincia":
        {
          if (!filter.hasOwnProperty("filter[provincia]")) {
            let params = {
              ...filter,
              ["filter[provincia]"]: value,
            };
            setFilter({ ...params });
            setEventos({ data: null });
            return fetch(setEventos, params, "/eventos");
          }

          if (
            filter["filter[provincia]"].split(",").length == 1 &&
            filter["filter[provincia]"].indexOf(value) !== -1
          ) {
            let params = filter;
            delete params["filter[provincia]"];
            setFilter({ ...params });
            setEventos({ data: null });
            return fetch(setEventos, params, "/eventos");
          }

          if (filter["filter[provincia]"].indexOf(value) !== -1) {
            let params = {
              ...filter,
              ["filter[provincia]"]: filter["filter[provincia]"].replace(
                "," + value,
                ""
              ),
            };

            setFilter({ ...params });
            setEventos({ data: null });
            return fetch(setEventos, params, "/eventos");
          } else {
            let params = {
              ...filter,
              ["filter[provincia]"]: `${filter["filter[provincia]"]},${value}`,
            };

            setFilter({ ...params });
            setEventos({ data: null });
            return fetch(setEventos, params, "/eventos");
          }
        }
        break;
      case "sala":
        {
          let params = {
            ...filter,
            ["filter[nombre_sitio]"]: value,
          };

          setFilter({ ...params });
          setSalas({ data: null });
          return fetch(setEventos, params, "/eventos");
        }
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <h4 className="GothamBold wow bounceInUp">Filtrar</h4>
      <hr className="wow bounceInUp" />
      <div className="d-flex align-items-center mb-4" id="calendar-home">
        <label className="btn bg-naranja text-white d-none">
          Aplicar filtro
          <button className="d-none"></button>
        </label>
      </div>
      <div className="mb-4">
        <div className="mb-3">
          <label for="exampleFormControlInput1" className="form-label">
            Desde
          </label>
          <input
            type="date"
            className="form-control"
            value={filter["filter[fecha]"].split(",")[0]}
            min={format(new Date(), "yyyy-M-dd").toString()}
            name="fecha.desde"
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="mb-3">
          <label for="exampleFormControlInput1" className="form-label">
            Hasta
          </label>
          <input
            type="date"
            className="form-control"
            value={filter["filter[fecha]"].split(",")[1]}
            min={format(new Date(), "yyyy-M-dd").toString()}
            name="fecha.hasta"
            onChange={(e) => onChange(e)}
          />
        </div>
      </div>
      <hr className="wow bounceInUp" />
      <div className="mb-4">
        <span className="GothamBold mb-0 mb-2 wow bounceInUp">Ubicación</span>
        <ul className="list-group">
          {provincias.map((provincia, index) => {
            if (filter.hasOwnProperty("filter[provincia]")) {
              if (filter["filter[provincia]"].includes(provincia)) {
                return (
                  <label
                    className="list-group-item d-flex justify-content-between py-1 w-auto mb-1"
                    key={index}
                  >
                    {`${provincia}`}
                    <i className="bi bi-x-lg"></i>
                    <button
                      className="d-none"
                      name="provincia"
                      onClick={(e) => onClick(e, provincia)}
                    ></button>
                  </label>
                );
              } else {
                return (
                  <label
                    className="list-group-item border-0 bg-transparent d-flex justify-content-between py-1 w-auto mb-1"
                    key={index}
                  >
                    {`${provincia} (200)`}
                    <button
                      className="d-none"
                      name="provincia"
                      onClick={(e) => onClick(e, provincia)}
                    ></button>
                  </label>
                );
              }
            } else {
              return (
                <label
                  className="list-group-item border-0 bg-transparent d-flex justify-content-between py-1 w-auto mb-1"
                  key={index}
                >
                  {`${provincia} (200)`}
                  <button
                    className="d-none"
                    name="provincia"
                    onClick={(e) => onClick(e, provincia)}
                  ></button>
                </label>
              );
            }
          })}
        </ul>
      </div>
      <div className="mb-4">
        <span className="GothamBold mb-0 mb-2 wow bounceInUp">Sala</span>

        <div className="input-group mb-3 position-relative">
          <input
            type="text"
            className="form-control"
            name="search"
            onChange={(e) => onChange(e)}
          />
          <span className="input-group-text btn-dark" id="basic-addon2">
            <i className="bi bi-search"></i>
          </span>
          {salas?.data ? (
            salas?.data.length == 0 ? (
              <ul
                className="list-group position-absolute"
                style={{ top: "40px" }}
              >
                <li className="list-group-item">
                  La sala no se encuentra disponible
                </li>
              </ul>
            ) : (
              <ul
                className="list-group position-absolute"
                style={{ top: "40px" }}
              >
                {salas.data.map((sala, index) => (
                  <label
                    className="list-group-item btn btn-outline-dark"
                    key={index}
                  >
                    {sala.nombre}
                    <button
                      className="d-none"
                      name="sala"
                      onClick={(e) => onClick(e, sala.nombre)}
                    ></button>
                  </label>
                ))}
              </ul>
            )
          ) : null}
        </div>
      </div>
      <div className="mb-4">
        <span className="GothamBold mb-0 mb-2 wow bounceInUp">Público</span>
        <div className="form-check mb-2 wow bounceInUp">
          <label className="form-check-label">Adulto</label>
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            name="grupo"
            onChange={(e) => onChange(e, "ADULTO")}
          />
        </div>
        <div className="form-check mb-2 wow bounceInUp">
          <label className="form-check-label">ATP</label>
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            name="atp"
            onChange={(e) => onChange(e, null)}
          />
        </div>
        <div className="form-check mb-2 wow bounceInUp">
          <label className="form-check-label">Infancias</label>
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            name="grupo"
            onChange={(e) => onChange(e, "INFANCIAS")}
          />
        </div>
      </div>
      <div className="mb-4">
        <span className="GothamBold mb-0 mb-2 wow bounceInUp">
          Tipo de Espectáculo
        </span>
        {categorias
          ? categorias.map((categoria, index) => {
              return (
                <div className="form-check mb-2 wow bounceInUp" key={index}>
                  <label className="form-check-label">{categoria.nombre}</label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    name="categoria"
                    onChange={(e) => onChange(e, categoria.nombre)}
                  />
                </div>
              );
            })
          : null}
      </div>
      <div className="mb-4 d-none">
        <span className="GothamBold mb-0 mb-2 wow bounceInUp">Descuentos</span>
        <div className="form-check mb-2 wow bounceInUp">
          <label className="form-check-label">10%</label>
          <input className="form-check-input" type="checkbox" value="" />
        </div>
        <div className="form-check mb-2 wow bounceInUp">
          <label className="form-check-label">20%</label>
          <input className="form-check-input" type="checkbox" value="" />
        </div>
      </div>
      <div>
        <label className="btn bg-naranja text-white d-none">
          Aplicar filtro
          <button className="d-none"></button>
        </label>
      </div>
    </div>
  );
};

export default Filtro;
