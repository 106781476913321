import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { facebookAuth, googleAuth, iniciarSession } from "../../../helpers/AccessHelper";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from 'react-google-login';
import Swal from "sweetalert2";

const AccesLogin = () => {

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    msg: "",
    class: "d-none",
  });

  
const history = useNavigate(); 

  const [pass, setPass] = useState({
    icon: "bi bi-eye-slash",
    type: "password",
    status: false,
  });

  const showPassword = (e) => {
    e.preventDefault();

    if (pass.status === false) {
      setPass({
        ...pass,
        icon: "bi bi-eye",
        type: "text",
        status: true,
      });
    } else {
      setPass({
        ...pass,
        icon: "bi bi-eye-slash",
        type: "password",
        status: false,
      });
    }
  };

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const onChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    setAlert({
      msg: "",
      class: "d-none",
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (form.email.trim() == "" || form.password.trim() == "") {
      setAlert({
        msg: "Todos los campos son obligatorios",
        class: "w-100 alert alert-danger text-start my-1",
      });
    } else {
      let dataForm = new FormData();
      dataForm.append("email", form.email);
      dataForm.append("password", form.password);

      
      iniciarSession(dataForm, setLoading, history);
    }
  };

  const responseFacebook = (response) => {
    let dataForm = new FormData;
    
    dataForm.append('nombre', response.name);
    dataForm.append('email', response.email);
    dataForm.append('facebook_user_id', response.id);
    
    let result; 

    const axios = async () => {
      result = await facebookAuth(dataForm);

      if(result.status == "DOBLE VALIDACION"){
        Swal.fire({
          title: 'Escribe tu contraseña para validar tu identidad',
          input: 'password',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'Enviar',
          showLoaderOnConfirm: true,
          customClass: {
            input: '',
            confirmButton: 'btn btn-custom-amarillo w-100',
            cancelButton: 'btn btn-outline-secondary'
          },
          buttonsStyling: false,
          preConfirm: (password) => {
            dataForm.append('password',password);
            facebookAuth(dataForm);
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              text: "Iniciando sesión",
              timer: "1500",
              icon: 'success',
              position: "bottom",
              showConfirmButton: false,
              customClass: {
                container: "add-to-cart-alert-container",
                popup: "add-to-cart-alert",
              }
            })
          }
        })
      }


    }
    axios();
    
  };

  const responseGoogle = (response) => {
    //console.log(response);

    
    let dataForm = new FormData;

    dataForm.append('email', response.profileObj.email);
    dataForm.append('google_user_id', response.googleId);
    dataForm.append('nombre', response.givenName);
    dataForm.append('apellido', response.familyName);
    
    let result; 
    
    const axios = async () => {
      result = await googleAuth(dataForm);

      if(result.status == "DOBLE VALIDACION"){
        Swal.fire({
          title: 'Escribe tu contraseña para validar tu identidad',
          input: 'password',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'Enviar',
          showLoaderOnConfirm: true,
          customClass: {
            input: '',
            confirmButton: 'btn btn-custom-amarillo w-100',
            cancelButton: 'btn btn-outline-secondary'
          },
          buttonsStyling: false,
          preConfirm: (password) => {
            dataForm.append('password',password);
            googleAuth(dataForm);
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              text: "Iniciando sesión",
              timer: "1500",
              icon: 'success',
              position: "bottom",
              showConfirmButton: false,
              customClass: {
                container: "add-to-cart-alert-container",
                popup: "add-to-cart-alert",
              }
            })
          }
        })
      }

    }
    axios();

  }

  return (
    <div className="container-md texto-normal d-flex justify-content-center">
      <div focus={true} name="upHTML" className="d-flex justify-content-center mx-auto pb-4">
        <form action="" className="p-0 p-md-4">
          <div className="text-center mb-3 mx-auto">
            <h1>
              <p>Ingresar a</p>
              <p>Antesala</p>
            </h1>

            <div className="w-100 text-center mb-3">
          <Link
              className="text-decoration-none"
              aria-label="Ingresar"
              to="/registro"
            >
              <span className="fw-light">¿No tienes una cuenta?</span>
              <span className="fw-bold fs-5"> Registrarse </span>
            </Link>
          </div>
          </div>

          <div className="mb-3">
            <label for="inputEmail" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control rounded-pill"
              id="inputEmail"
              placeholder="Ingrese su Email"
              name="email"
              onChange={(e) => onChange(e)}
            />
          </div>

          <div className="mb-3">
            <label for="inputPassoword" className="form-label">
              Contraseña
            </label>
            <div class="input-group mb-3">
              <button
                className="input-group-text  border-0 rounded-end rounded-pill px-3"
                id="basic-addon1"
                onClick={(event) => showPassword(event)}
              >
                <i className={pass.icon}></i>
              </button>
              <input
                type={pass.type}
                class="form-control rounded-start rounded-pill"
                placeholder="Ingrese su contraseña"
                aria-label="Password"
                aria-describedby="basic-addon1"
                name="password"
                onChange={(event) => onChange(event)}
              />
            </div>
          </div>

         <button
            className="btn btn-custom-amarillo w-100 mb-3 mx-1 "
            onClick={onSubmit}
            disabled={loading? true : false}
          > 
            {
              loading?
              <span class="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span>
              :
              ""
            }
            Iniciar Sesion
          </button>

          
          
          
          <ul className="list-group mb-1">
            <li className="list-group-item border-0 text-muted p-0 m-0 text-center " style={{fontSize: "14px"}}>
              Ingresa con tu cuenta de Google o Facebook
            </li>
          </ul>


          <div className="w-100 text-center">
            <Link className="text-decoration-none d-block" to="/TYP">
              <span className="text-dark">Al ingresar acepta nuestros</span>{" "}
              Terminos y Condiciones
            </Link>

            <Link className="text-decoration-none" to="/Restaurar">
              {" "}
              - Olvide mi contraseña{" "}
            </Link>
          </div>

          <div className={alert.class}>
            <p className="fw-bold">Atención.</p>
            <p>{alert.msg}</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AccesLogin;
