export const TotalEntradaUtils = (entradas, comision) => {
  let subTotal = 0;
  let serv = 0;

  entradas.map((e, index) => {
    if (e.quantity) {
      subTotal = subTotal + e.pricexquantity;
      serv = serv + (e.pricexquantity / 100) * comision;
    }
  });

  return {
    subTotal: subTotal,
    serv: serv,
    total: subTotal + serv,
  };
};

export const strRandom = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};
