const CardPlaceholder = ({ width, height }) => {
  return (
    <div
      className="w-100 box-evento-hover border overflow-hidden border placeholder-wave"
      style={{ borderRadius: "20px", width: width ?? "", height: height ?? "300px",  }}
      aria-hidden="true"
    ><span class="placeholder w-100 d-block" style={{height: height ?? "300px", background: "#c6c6c6"}}></span></div>
  );
};

export default CardPlaceholder;
