import { useState } from "react";
import { PATH_EVENTO_PRODUCTION } from "../../../const/PATH";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { EventoSlug } from "../../utils/EventoSlug";

const Evento = ({ descount, data }) => {
  const [image, setImage] = useState(
    `${PATH_EVENTO_PRODUCTION}${data?.imagen}`
  );

  const manejarError = () => {
    setImage("/assets/img/eventos/imageNotFound.svg");
  };

  if (descount) {
    return (
      <a
        href={`/v/entrada/${EventoSlug(data?.nombre, data?.id)}`}
        title="Ir al evento"
      >
        <div
          className="w-100 box-evento-hover border border-3 overflow-hidden"
          style={{ borderRadius: "20px" }}
        >
          <div className="box-evento position-relative">
            <div className="overflow-hidden" style={{ height: "300px" }}>
              <div className="text-white cardOffer d-flex align-items-center justify-content-center">
                <span className="d-block GothamBold fs-5">10%</span>
              </div>
              <img
                src={image}
                className="w-100"
                style={{ height: "120%" }}
                onError={manejarError}
              />
            </div>

            <div
              className="w-100 text-white text-center Grifter bg-naranja"
              style={{ borderRadius: "0px 0px 0px 0px", fontSize: "10px" }}
            >
              {data?.categorias[0].nombre}
            </div>

            <div className="text-center px-3 py-2">
              <h1 className="Grifter fs-6  text-dark fs-6">{data?.nombre}</h1>
              <div className="precio fw-bold fs-4  mt-2 text-dark">
                <span
                  className="d-block text-muted"
                  style={{ fontSize: "10px" }}
                >
                  Desde
                </span>
                $
                {data?.entradas[0].precio <= 0
                  ? "GRATIS"
                  : data?.entradas[0].precio}
              </div>
              <span className="d-block text-dark" style={{ fontSize: "12px" }}>
                {data?.fechas.length > 0
                  ? `${format(
                      new Date(data?.fechas[0].fecha),
                      "eeee, d 'de' MMMM 'de' yyyy",
                      { locale: es }
                    ).toString()} | ${data?.fechas[0].hora} Hrs`
                  : null}
              </span>
              <span
                className="text-dark w-100 text-center"
                style={{ fontSize: "12px" }}
              >
                {`${data?.nombre_sitio} | ${data?.localidad} - ${data?.provincia}`}
              </span>
              <button className="btn btn-custom-amarillo Grifter py-1 my-2">
                Comprar entradas
              </button>
            </div>
          </div>
        </div>
      </a>
    );
  }

  return (
    <a
      href={`/v/entrada/${EventoSlug(data?.nombre, data?.id)}`}
      title="Ir al evento"
    >
      <div
        className="w-100 box-evento-hover border border-3 overflow-hidden"
        style={{ borderRadius: "20px" }}
      >
        <div className="box-evento position-relative">
          <div className="overflow-hidden" style={{ height: "300px" }}>
            <div className="text-white cardOffer d-none">
              <span className="d-block GothamBold fs-5">25</span>
              <span className="d-block GothamBold fs-7 text-white">Abril</span>
            </div>
            <img
              src={image}
              className="w-100"
              style={{ height: "120%" }}
              onError={manejarError}
            />
          </div>

          <div
            className="w-100 text-white text-center Grifter bg-naranja"
            style={{ borderRadius: "0px 0px 0px 0px", fontSize: "10px" }}
          >
            {data?.categorias[0].nombre}
          </div>

          <div className="text-center px-3 py-2">
            <h1 className="Grifter fs-6  text-dark fs-6">{data?.nombre}</h1>
            <div className="precio fw-bold fs-4  mt-2 text-dark">
              <span className="d-block text-muted" style={{ fontSize: "10px" }}>
                Desde
              </span>
              $
              {data?.entradas[0].precio <= 0
                ? "GRATIS"
                : data?.entradas[0].precio}
            </div>
            <span className="d-block text-dark" style={{ fontSize: "12px" }}>
              {data?.fechas.length > 0
                ? `${format(
                    new Date(data?.fechas[0].fecha),
                    "eeee, d 'de' MMMM 'de' yyyy",
                    { locale: es }
                  ).toString()} | ${data?.fechas[0].hora} Hrs`
                : null}
            </span>
            <span
              className="text-dark w-100 text-center"
              style={{ fontSize: "12px" }}
            >
              {`${data?.nombre_sitio} | ${data?.localidad} - ${data?.provincia}`}
            </span>
            <button className="btn btn-custom-amarillo Grifter py-1 my-2">
              Comprar entradas
            </button>
          </div>
        </div>
      </div>
    </a>
  );
};

export default Evento;
