import { useContext, useState } from "react";
import { TotalEntradaUtils } from "../../utils/TotalEntradaUtils";

const ComprarEntradas = ({
  setStep,
  step,
  entradas,
  onClick,
  comission,
  form,
  evento,
  fechas,
  label,
  image
}) => {

  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog mx-auto" style={{ minWidth: "100%" }}>
        <div className="modal-content" style={{ borderRadius: "30px" }}>
          <div className="modal-header border-0">
            <h5 className="modal-title" id="exampleModalLabel"></h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-5">
                <div
                  className="border h-100"
                  style={{
                    backgroundImage: `url(${image})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "30px",
                  }}
                ></div>
              </div>
              <div className="col-md-7">
                <div>
                  <nav>
                    <div
                      className="nav nav-tabs d-flex justify-content-center align-items-center border-0"
                      id="nav-tab"
                      role="tablist"
                    >
                      <button
                        className={
                          step == 0
                            ? "bg-transparent fw-bold text-custom-naranja border-0 d-flex items-align-center d-flex align-items-center"
                            : "bg-transparent fw-bold text-dark border-0 d-flex items-align-center"
                        }
                        onClick={() => setStep(0)}
                        id="nav-fecha-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-fecha"
                        type="button"
                        role="tab"
                        aria-controls="nav-fecha"
                        aria-selected="true"
                      >
                        <i
                          className={
                            step == 0
                              ? "bi bi-1-circle-fill text-custom-naranja fw-bold titulo-size me-2"
                              : "bi bi-1-circle-fill text-dark fw-bold titulo-size me-2"
                          }
                        ></i>
                        <span className="fs-4 titulo-size Grifter">
                          Elegir fecha
                        </span>
                      </button>

                      <div
                        className="d-none d-md-block"
                        style={{
                          borderBottom: "2px solid black",
                          width: "60px",
                          height: "1px",
                        }}
                      ></div>

                      <button
                        className={
                          step == 1
                            ? "bg-transparent fw-bold text-custom-naranja border-0 d-flex items-align-center "
                            : "bg-transparent fw-bold text-dark border-0 d-flex items-align-center "
                        }
                        onClick={() => setStep(1)}
                        id="nav-cantidad-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-cantidad"
                        type="button"
                        role="tab"
                        aria-controls="nav-cantidad"
                        aria-selected="false"
                      >
                        <i
                          className={
                            step == 1
                              ? "bi bi-2-circle-fill text-custom-naranja fw-bold titulo-size me-2"
                              : "bi bi-2-circle-fill text-dark fw-bold titulo-size me-2"
                          }
                        ></i>
                        <span className="titulo-size Grifter">
                          Elegir entrada
                        </span>
                      </button>
                      <div
                        className="d-none d-md-block"
                        style={{
                          borderBottom: "1px solid black",
                          width: "60px",
                          height: "1px",
                        }}
                      ></div>
                      <button
                        className={
                          step == 2
                            ? "bg-transparent fw-bold text-custom-naranja border-0 d-flex items-align-center me-3"
                            : "bg-transparent fw-bold text-dark border-0 d-flex items-align-center me-3"
                        }
                        onClick={() => setStep(2)}
                        id="nav-pagar-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-pagar"
                        type="button"
                        role="tab"
                        aria-controls="nav-contact"
                        aria-selected="false"
                      >
                        <i
                          className={
                            step == 2
                              ? "bi bi-3-circle-fill text-custom-naranja fw-bold titulo-size me-2"
                              : "bi bi-3-circle-fill text-dark fw-bold titulo-size me-2"
                          }
                        ></i>
                        <span className="titulo-size Grifter">Pagar</span>
                      </button>
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      style={{ height: "500px" }}
                      id="nav-fecha"
                      role="tabpanel"
                      aria-labelledby="nav-fecha-tab"
                      tabindex="0"
                    >
                      <div
                        className="py-5 overflow-auto border-0 border-dark"
                        style={{ height: "500px" }}
                        id="content-calendar"
                      >
                        <ul class="list-group">
                          {fechas.map((fecha) => {
                            return fecha.fechas.map((dia) => {
                              if (form.fecha == `${dia} Hora: ${fecha.hora}`) {
                                return (
                                  <li class="list-group-item border-0 mb-0 p-0">
                                    <label
                                      className={
                                        "w-100 btn Grifter mb-2 text-start m-0"
                                      }
                                      style={{
                                        border: "2px solid #d0552a",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      {`${dia} Hora: ${fecha.hora}`}
                                      <button
                                        className="d-none"
                                        name="remove-date"
                                        onClick={(e) =>
                                          onClick(e, {
                                            select: `${dia} Hora: ${fecha.hora}`,
                                          })
                                        }
                                      ></button>
                                    </label>
                                  </li>
                                );
                              }
                              return (
                                <li class="list-group-item border-0 mb-0 p-0">
                                  <label
                                    className={
                                      "w-100 btn border Grifter text-start mb-2  m-0"
                                    }
                                    style={{ borderRadius: "10px" }}
                                  >
                                    {`${dia} Hora: ${fecha.hora}`}
                                    <button
                                      className="d-none"
                                      name="add-date"
                                      onClick={(e) =>
                                        onClick(e, {
                                          select: `${dia} Hora: ${fecha.hora}`,
                                          stock: fecha.stock,
                                        })
                                      }
                                    ></button>
                                  </label>
                                </li>
                              );
                            });
                          })}
                        </ul>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade py-5"
                      id="nav-cantidad"
                      role="tabpanel"
                      aria-labelledby="nav-cantidad-tab"
                      tabindex="0"
                    >
                      {entradas.map((entrada, index) => {
                        return (
                          <div>
                            <div
                              className="text-center  d-md-flex d-block justify-content-between"
                              style={{
                                opacity: entrada.quantity ? 1 : 0.5,
                              }}
                            >
                              <div className="fw-bold fs-6 text-start text-dark d-block">
                                <div>{entrada.nombre}</div>
                              </div>
                              <span className="fw-bold fs-5 text-dark">
                                ${entrada.pricexquantity ?? entrada.precio}
                                ARS
                              </span>
                              <div>
                                <div className=" d-flex justify-content-center">
                                  <button
                                    className=" btn py-2 px-4 fw-bold fs-6 border border-dark"
                                    style={{
                                      borderRadius: "20px 0px 0px 20px",
                                    }}
                                    name="remove"
                                    onClick={(event) =>
                                      onClick(event, {
                                        ...entrada,
                                        index: index,
                                      })
                                    }
                                  >
                                    -
                                  </button>
                                  <input
                                    className="form-controll py-1 text-center fs-6 GothamBold"
                                    type="number"
                                    value={entrada.quantity ?? 0}
                                    style={{ width: "100px" }}
                                  />
                                  <button
                                    className="border-dark btn py-1 px-4 fw-bold fs-6 border"
                                    style={{
                                      borderRadius: "0px 20px 20px 0px",
                                    }}
                                    name="add"
                                    onClick={(event) =>
                                      onClick(event, {
                                        ...entrada,
                                        index: index,
                                      })
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                                <span className="" style={{ fontSize: "10px" }}>
                                  {"+Serv ARS " + entrada.serv ?? 0} Quedan{" "}
                                  {entrada.remain ?? 0} lugares!
                                </span>
                              </div>
                            </div>

                            <hr></hr>
                          </div>
                        );
                      })}

                      <div className="row">
                        <div className="col-md-6 mb-2">
                          <span className="d-block fw-bold">
                            {evento?.nombre}
                          </span>
                          <span className="d-block">
                            {evento?.nombre_sitio}
                          </span>
                          <span className="d-block">{form.fecha}</span>
                        </div>
                        <div className="col-md-6">
                          <div className="w-100 d-flex justify-content-between">
                            <div className="fs-6 fw-bold">SubTotal</div>
                            <div className="fs-6 fw-bold">
                              {"ARS " +
                                TotalEntradaUtils(evento.entradas, comission)
                                  .subTotal}
                            </div>
                          </div>
                          <div className="w-100 d-flex justify-content-between">
                            <div className="fs-6 fw-bold">+Serv 15%</div>
                            <div className="fs-6 fw-bold">
                              {"ARS " +
                                TotalEntradaUtils(evento.entradas, comission)
                                  .serv}
                            </div>
                          </div>
                          <div className="w-100 d-flex justify-content-between">
                            <div className="fs-6 fw-bold">Total</div>
                            <div className="fs-6 fw-bold">
                              {"ARS " +
                                TotalEntradaUtils(evento.entradas, comission)
                                  .total}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="nav-pagar"
                      role="tabpanel"
                      aria-labelledby="nav-pagar-tab"
                      tabindex="0"
                    >
                      <ul class="list-group">
                        <li class="px-3 py-3 rounded-0 list-group-item p-0 m-0 mb-1 border-start-0 border-end-0">
                          <span className="GothamBold d-block">Fecha</span>
                          {form.fecha}
                        </li>

                        <li class="px-3 py-3 rounded-0 list-group-item p-0 m-0 border-start-0 border-end-0 ">
                          <span className="GothamBold d-block">Entradas</span>
                          {evento?.entradas.map((entrada, index) => {
                            if (entrada.quantity > 0) {
                              return (
                                <div
                                  className="d-flex justify-content-between"
                                  key={index}
                                >
                                  <span className="GothamBold fs-6">
                                    {`${entrada.nombre} x${entrada.quantity}`}
                                  </span>
                                  <span className="GothamBold fs-6">{`ARS ${entrada.pricexquantity}`}</span>
                                </div>
                              );
                            }
                          })}
                        </li>

                        <li class="px-3 py-3 rounded-0 list-group-item p-0 m-0 border-start-0 border-end-0 ">
                          <div className="d-flex justify-content-between">
                            <span className="GothamBold fs-6">SubTotal</span>
                            <span className="GothamBold fs-6">
                              {" "}
                              {"ARS " +
                                TotalEntradaUtils(evento.entradas, comission)
                                  .subTotal}
                            </span>
                          </div>
                        </li>

                        <li class="px-3 py-3 rounded-0 list-group-item p-0 m-0 border-start-0 border-end-0 ">
                          <div className="d-flex justify-content-between">
                            <span className="GothamBold fs-6">+Serv(15%)</span>
                            <span className="GothamBold fs-6">
                              {" "}
                              {"ARS " +
                                TotalEntradaUtils(evento.entradas, comission)
                                  .serv}
                            </span>
                          </div>
                        </li>

                        <li class={`px-3 py-3 rounded-0 list-group-item p-0 m-0 border-start-0 border-end-0 ${evento.cupones.length > 0?'':'d-none'}`}>
                          <div className="d-flex justify-content-between">
                            <span className="GothamBold fs-6">
                              Ingresar codigo de descuento
                              <input
                                type="text"
                                className="d-block py-2 w-100"
                                style={{
                                  border: "1px solid #d0552a",
                                  borderRadius: "10px",
                                }}
                              />
                              <span
                                className="text-muted"
                                style={{ fontSize: "10px" }}
                              >
                                Hay un codigo de descuento habilitado
                              </span>
                            </span>
                            <span className="GothamBold fs-5"></span>
                          </div>
                        </li>

                        <li class="px-3 py-3 rounded-0 list-group-item p-0 m-0 border-start-0 border-end-0 ">
                          <div className="d-flex justify-content-between">
                            <span className="GothamBold fs-5">
                              Puedes abonar con tu membresia
                              <label className="d-block btn btn-outline-dark border-custom-naranja">
                                <span>Membresia ADIUC</span>
                                <span
                                  className="text-muted d-block"
                                  style={{ fontSize: "10px" }}
                                >
                                  Puedes abonar una entrada
                                </span>
                                <button className="d-none"></button>
                              </label>
                            </span>
                            <span className="GothamBold fs-5"></span>
                          </div>
                        </li>

                        <li class="px-3 py-3 rounded-0 list-group-item p-0 m-0 border-0 ">
                          <div className="d-flex justify-content-between">
                            <span className="GothamBold fs-5">Total</span>
                            <span className="GothamBold fs-5">
                              {"ARS " +
                                TotalEntradaUtils(evento.entradas, comission)
                                  .total}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <label
                    className={
                      step == 0
                        ? "d-none"
                        : "btn btn-outline-dark fw-normal py-4 fs-6 d-flex justify-content-center align-items-center  me-2"
                    }
                    style={{
                      width: "230px",
                      lineHeight: 1,
                      padding: "10px 20px 10px",
                      borderRadius: "30px",
                    }}
                  >
                    <i class="bi bi-chevron-left fs-6"></i>
                    Atras
                    <button
                      className="d-none"
                      onClick={(e) => {
                        let int = step - 1;
                        switch (int) {
                          case 0:
                            {
                              document.getElementById("nav-fecha-tab").click();
                            }
                            break;
                          case 1:
                            {
                              document
                                .getElementById("nav-cantidad-tab")
                                .click();
                            }
                            break;
                          case 2:
                            {
                            }
                            break;
                          default:
                            break;
                        }
                        setStep(int);
                      }}
                    ></button>
                  </label>

                  {label(step)}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer border-0"></div>
        </div>
      </div>
    </div>
  );
};

export default ComprarEntradas;
