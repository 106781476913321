import { useState } from "react";
import { PATH_NOTA_PRODUCTION } from "../../../const/PATH";
import { format } from "date-fns";
import { es } from "date-fns/locale";

const Nota = ({ data }) => {
  const [image, setImage] = useState(`${PATH_NOTA_PRODUCTION}${data?.imagen}`);

  const manejarError = () => {
    setImage("/assets/img/eventos/imageNotFound.svg");
  };

  return (
    <div class="overflow-hidden " style={{ width: "100%", zIndex: 2, border: "1px solid #3d3d3d" }}>
      <div
        style={{
          position: "relative",
          width: "100%",
          maxWidth: "600px",
          height: "400px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              "linear-gradient(to bottom, transparent, transparent, rgba(61, 61, 61, 0.7), rgba(61, 61, 61, 0.7), rgba(61, 61, 61, 0.7))",
          }}
        ></div>
        <img
          src={image}
          width={"100%"}
          height={"100%"}
          alt=""
          onError={manejarError}
        />
      </div>

      <div
        className="text-white px-3 py-4 border-0 box-content"
        style={{
          position: "absolute",
          bottom: "0px",
          left: "0px",
          zIndex: 1,
          borderRadius: "0px 0px 0px 0px",
        }}
      >
        <div className="mb-2">
          <span className="fs-7 mb-2 text-white d-block GothamLight">
            {format(new Date(data?.created_at), "eeee, d 'de' MMMM 'de' yyyy", {
              locale: es,
            }).toString()}
          </span>
          <span className="fs-5 GothamBold text-uppercase ">{data.titulo}</span>
        </div>
        <p className="fs-8 GothamLight lh-1">{data.descripcion}</p>
        <a
          href="#"
          className="d-block py-2 btn px-2 text-white border-0"
          style={{ borderRadius: "20px" }}
        >
          <span className="d-flex fs-8 GothamLight justify-content-start items-align-center">
            Leé la nota completa <i class="ms-2 bi bi-arrow-right fs-6"></i>
          </span>
        </a>
      </div>
    </div>
  );
};

export default Nota;
