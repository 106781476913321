import TYP from "../../../components/layout/TYP/TYP";
import Footer from "../components/body/Footer";
import Header from "../components/body/Header";

const TerminosYCondiciones = () => {
  return (
    <div className="container-fluid px-0 bg-light">
      <div className="w-100 shadow-sm mb-1 fixed-top bg-white">
        <Header discount={false} />
      </div>
      <div style={{marginTop: "40px"}}>
      <TYP />
      </div>
      <div className="w-100 container-fluid px-0 mx-0">
        <Footer />
      </div>
    </div>
  );
};

export default TerminosYCondiciones;
