import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // estilos
import "react-date-range/dist/theme/default.css"; // tema
import Evento from "../components/eventos/Evento";
import { PATH_GALERIA_PRODUCTION } from "../../const/PATH";
import CardPlaceholder from "../components/load/CardPlaceholder";

const Inicio = ({
  CalendarProps,
  banners,
  eventosRecomendados,
  eventosDescuento,
  eventosMes,
}) => {
  const isDateMarked = (date) => {
    if (eventosMes.length > 0) {
      return eventosMes.some(
        (markedDate) =>
          date.getDate() === markedDate.getDate() &&
          date.getMonth() === markedDate.getMonth() &&
          date.getFullYear() === markedDate.getFullYear()
      );
    }
  };

  //console.log({ eventosRecomendados });
  //console.log({banners});
  return (
    <main className="m-0" style={{ paddingTop: "100px" }}>
      <section className="mt-1 mb-3 p-0">
        <div className="container">
          <div>
            <Carousel
              responsive={{
                superLargeDesktop: {
                  breakpoint: { max: 4000, min: 3000 },
                  items: 1,
                },
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 1,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 1,
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                },
              }}
              infinite={true}
              autoPlay={true}
            >
              {banners ? (
                banners.map((banner, index) => {
                  if (banner.vigencia) {
                    let fechas = JSON.parse(banner.vigencia);
                    if (new Date(fechas[0]).getTime() < new Date().getTime()) {
                      return (
                        <div
                          className="bg-light d-flex"
                          key={index}
                        >
                          <img
                            src={`${PATH_GALERIA_PRODUCTION}${banner.imagen}`}
                            className="img-banner m-auto"
                            alt=""
                          />
                        </div>
                      );
                    }
                  }
                })
              ) : (
                <div
                  className="bg-light"
                  style={{
                    height: "350px",
                    borderRadius: "20px",
                  }}
                >
                  <CardPlaceholder width={"100%"} height="350px" />
                </div>
              )}
            </Carousel>
          </div>
        </div>
      </section>

      <section className="w-100 border-0">
        <div className="row">
          <div className="col-12 col-md-6 d-flex justify-content-center  mb-4 align-items-center  border-0">
            <div>
              <h1 className="fs-4">Qué hacer?</h1>
              <div className="p-0 p-md-0 d-flex justify-content-center">
                <DateRange
                  onChange={(item) => {
                    console.log(item);
                    //CalendarProps.setState([{...item}])
                  }}
                  locale={CalendarProps.locale}
                  minDate={new Date()}
                  ranges={CalendarProps.state}
                  color={"#3d3d3d"}
                  rangeColors={["white"]}
                  showDateDisplay={false}
                  editableDateInputs={true}
                  moveRangeOnFirstSelection={false}
                  dayContentRenderer={(date, index) => {
                    const marked = isDateMarked(date);
                    const clases = marked
                      ? "bg-naranja rounded-md text-white px-2 py-0 GothamBold position-relative"
                      : "GothamBold";
                    return (
                      <div
                        className={`${clases} d-flex align-items-center`}
                        style={{ borderRadius: "10px", height: "25px" }}
                        key={index}
                      >
                        {date.getDate()}
                        {marked ? (
                          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-amarillo">
                            10+
                            <span className="visually-hidden">
                              unread messages
                            </span>
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 d-flex justify-content-center d-flex border-0">
            <a
              href={"#"}
              className="big-button today d-flex w-custom-btn align-items-center justify-content-center"
              style={{ height: "300px" }}
            >
              <h1 className="fs-4">hoy</h1>
            </a>
            <a
              href={"#"}
              className="big-button weekend  w-custom-btn d-flex align-items-center justify-content-center"
              style={{ height: "300px" }}
            >
              <h1 className="fs-4">el finde</h1>
            </a>
            <a
              href={"#"}
              className="big-button month w-custom-btn d-flex align-items-center justify-content-center"
              style={{ height: "300px" }}
            >
              <h1 className="fs-4">este mes</h1>
            </a>
          </div>
        </div>
      </section>

      <section id="eventos" className="mb-5">
        <div className="container text-center">
          <h2 className="fs-5">Obras recomendadas</h2>
          <div className="">
            <Carousel
              responsive={{
                superLargeDesktop: {
                  breakpoint: { max: 4000, min: 3000 },
                  items: 5,
                },
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 4,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 2,
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                },
              }}
              infinite={true}
              autoPlay={true}
            >
              {eventosRecomendados?.data
                ? eventosRecomendados?.data.map((evento, index) => (
                    <div
                      className="item p-2"
                      style={{
                        background: "none",
                        width: "100%",
                        borderRadius: "20px",
                      }}
                      key={index}
                    >
                      <Evento data={evento} />
                    </div>
                  ))
                : [1, 2, 3, 4].map((evento, index) => (
                    <div
                      className="item"
                      style={{
                        background: "none",
                        width: "100%",
                        borderRadius: "20px",
                      }}
                      key={index}
                    >
                      <CardPlaceholder />
                    </div>
                  ))}
            </Carousel>
          </div>
        </div>
      </section>

      <section id="eventos" className="mb-5">
        <div className="container text-center">
          <h2
            className={`fs-5 ${
              eventosDescuento?.data?.length > 0 ? "" : "d-none"
            }`}
          >
            Obras con descuento
          </h2>
          <div className="">
            <Carousel
              responsive={{
                superLargeDesktop: {
                  breakpoint: { max: 4000, min: 3000 },
                  items: 5,
                },
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 4,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 2,
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                },
              }}
              infinite={true}
              autoPlay={true}
            >
              {eventosDescuento?.data
                ? eventosDescuento?.data.map((evento, index) => (
                    <div
                      className="item p-1"
                      style={{
                        background: "none",
                        width: "100%",
                        borderRadius: "20px",
                      }}
                      key={index}
                    >
                      <Evento data={evento} descount={true} />
                    </div>
                  ))
                : [1, 2, 3, 4].map((evento, index) => (
                    <div
                      className="item p-1"
                      style={{
                        background: "none",
                        width: "100%",
                        borderRadius: "20px",
                      }}
                      key={index}
                    >
                      <CardPlaceholder />
                    </div>
                  ))}
            </Carousel>
          </div>
        </div>
      </section>

      <section id="apoyo" className="wow bounceInUp p-0 m-0 mb-5 d-none">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mb-200">
              <div className="text-center bg-gris-oscuro br-10 pt-50 pb-50 pe-4 ps-4">
                <h1 className="fc-blanco m-0">contamos con el apoyo de</h1>
              </div>
            </div>
            <div className="col-md-4 d-flex align-items-center justify-content-center">
              <div className="wrapper">
                <div className="carousel">
                  <div className="carousel__item">
                    <div className="carousel__item-body">
                      <img src="/assets/img/img-teatro-01" alt="" />
                    </div>
                  </div>
                  <div className="carousel__item">
                    <div className="carousel__item-body">
                      <img src="/assets/img/img-teatro-01" alt="" />
                    </div>
                  </div>
                  <div className="carousel__item">
                    <div className="carousel__item-body">
                      <img src="/assets/img/img-teatro-01" alt="" />
                    </div>
                  </div>
                  <div className="carousel__item">
                    <div className="carousel__item-body">
                      <img src="assets/img/logo-04.png" alt="" />
                    </div>
                  </div>
                  <div className="carousel__item">
                    <div className="carousel__item-body">
                      <img src="assets/img/logo-05.png" alt="" />
                    </div>
                  </div>
                  <div className="carousel__item">
                    <div className="carousel__item-body">
                      <img src="assets/img/logo-nmg.svg" alt="" />
                    </div>
                  </div>
                  <div className="carousel__item">
                    <div className="carousel__item-body">
                      <img src="assets/img/logo-01.png" alt="" />
                    </div>
                  </div>
                  <div className="carousel__item">
                    <div className="carousel__item-body">
                      <img src="assets/img/logo-02.png" alt="" />
                    </div>
                  </div>
                  <div className="carousel__item">
                    <div className="carousel__item-body">
                      <img src="assets/img/logo-03.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Inicio;
